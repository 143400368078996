.latest-collections {
  margin-bottom: 10rem;
}

.latest-collections .section__title {
  margin-bottom: 6rem;
}

.latest-collections__container {
  position: relative;
}

.latest-collections__swiper-container {
  position: relative;
}

.latest-collections__swiper {
  position: relative;
  overflow: hidden;
  margin: 0 -5rem;
  padding: 0 5rem;
  -webkit-mask-image: linear-gradient(
    to left,
    transparent,
    #000 5rem,
    #000 calc(100% - 5rem),
    transparent
  );
}

.latest-collections__swiper-container .swiper-button-prev,
.latest-collections__swiper-container .swiper-button-next {
  position: absolute;
  top: calc(50% - 2rem);
  z-index: 89;
  width: 4rem;
  height: 4rem;
  background: rgba(255, 255, 255, 0.1);
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(19px);
  border-radius: 1rem;
}

.latest-collections__swiper-container .swiper-button-prev {
  left: -2rem;
}

.latest-collections__swiper-container .swiper-button-next {
  right: -2rem;
}

.latest-collections__swiper-container .swiper-button-prev:after,
.latest-collections__swiper-container .swiper-button-next:after {
  content: '';
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background-image: url('/ui-icons/arrow-left.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.latest-collections__swiper-container .swiper-button-next:after {
  transform: rotate(180deg);
}

.latest-collections__swiper-container .swiper-pagination {
  position: relative;
  margin-top: 4rem;
}

.latest-collections__swiper-container
  .swiper-pagination
  .swiper-pagination-bullet:not(swiper-pagination-bullet-active) {
  height: 0.2rem;
  width: 4rem;
  background-color: #ffffff;
  border-radius: 0;
}

.latest-collections__swiper-container
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 11.2rem;
  height: 0.2rem;
  background-color: var(--plum);
  border-radius: 0;
}

.latest-collections__swiper-container .swiper-button-disabled {
  display: none;
}

@media (max-width: 768px) {
  .latest-collections {
    margin-bottom: 8rem;
  }

  .latest-collections .section__title {
    margin-bottom: 3rem;
  }

  .latest-collections__swiper {
    max-width: calc(100% - 5rem);
    box-sizing: content-box;
  }

  .latest-collections__swiper-container
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 3rem;
  }

  .latest-collections__swiper-container
    .swiper-pagination
    .swiper-pagination-bullet:not(swiper-pagination-bullet-active) {
    width: 0.4rem;
  }
}

@media (max-width: 650px) {
  .latest-collections__swiper {
    max-width: 100%;
    margin: 0 -1rem;
    padding: 0 1rem;
    -webkit-mask-image: linear-gradient(
      to left,
      transparent,
      #000 1rem,
      #000 calc(100% - 1rem),
      transparent
    );
  }

  .latest-collections__swiper-container .swiper-button-prev {
    left: -1rem;
  }

  .latest-collections__swiper-container .swiper-button-next {
    right: -1rem;
  }
}
