.card {
  position: relative;
  box-sizing: border-box;
  border-radius: 1.5rem;
  width: 19rem;
  max-height: 30rem;
  background-color: var(--dark-gray);
  cursor: pointer;
  transition: all 0.1s ease 0s;
}

.card:hover {
  transform: translate(0, -0.3rem);
}

.card__cover {
  position: relative;
  width: 100%;
  height: 19rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.card__cover > img {
  object-fit: cover;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.card__container {
  padding: 1rem;
  box-sizing: border-box;
}

.card__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

@media (max-width: 768px) {
  .card {
    width: 17.4rem;
  }
}

@media (max-width: 414px) {
  .card {
    width: 16.4rem;
  }
}
