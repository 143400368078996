.skeleton-avatar {
  position: relative;
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  border-radius: 50%;
  overflow: hidden;
}

.skeleton-avatar.skeleton-avatar--loading {
  pointer-events: none;
  user-select: none;
  background: padding-box #3b3c41;
}

.skeleton-avatar.skeleton-avatar--loading *,
.skeleton-avatar.skeleton-avatar--loading:before,
.skeleton-avatar.skeleton-avatar--loading:after {
  visibility: hidden;
}

.skeleton-avatar.skeleton-avatar--active {
  -webkit-animation: skeleton-active 1.4s ease;
  -webkit-animation-iteration-count: infinite;
}

@media (max-width: 575px) {
  .skeleton-avatar {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
  }
}
