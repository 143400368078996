.search-result {
  padding: 1.6rem 0;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
  background: rgba(59, 60, 65, 0.7);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transform: translate3d(0, 0, 0);
  border-radius: 1.6rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.1);
  overflow-y: auto;
  max-height: calc(100vh - 9rem);
}

.search-result__section {
  height: 100%;
}

.search-result__section:not(:last-child) {
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.search-result__section:not(:first-child) {
  padding-top: 2rem;
}

.search-result__list {
  position: relative;
  display: flex;
  flex-direction: column;
}

.search-result__list-item {
}

.search-result__category {
  display: flex;
  align-items: center;
  padding: 0.7rem 1.6rem;
}

.search-result__category .avatar {
  margin-right: 1rem;
}

.search-result__collection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.6rem;
}

.search-result__collection .avatar {
  margin-right: 1rem;
}

.search-result__collection:hover {
  background: rgba(255, 255, 255, 0.1);
}

.search-result__collection-name {
  display: flex;
  align-items: center;
}

.search-result__collection-name .avatar {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  object-fit: cover;
  border-radius: 50%;
}

.search-result__collection-value {
  display: flex;
  align-items: center;
}

.search-result__collection-value img {
  opacity: 0.5;
}

.search-result__collection-value > .text {
  margin-left: 0.6rem;
}

/* result asset */

.search-result__asset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.6rem;
}

.search-result__asset:hover {
  background: rgba(255, 255, 255, 0.1);
}

.search-result__asset-name {
  display: flex;
  align-items: center;
}

.search-result__asset-name .avatar {
  margin-right: 1rem;
}

.search-result .loader {
  margin: 3.6rem auto;
}

.search-result__title {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  font-size: 1.6rem;
}

.search-result__title img {
  margin-right: 0.6rem;
  width: 1.8rem;
  height: 1.8rem;
}
