.online-badge {
  position: relative;
  padding-left: 1.4rem;
}

.online-badge__online {
  position: absolute;
  left: 0;
  top: calc(50% - 0.4rem);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: var(--online);
  -webkit-animation: pulsate 3s ease;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
