.join-us {
  position: relative;
  margin-bottom: 10rem;
}

.join-us__container {
  height: 100%;
}

.join-us__bg {
  z-index: -1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  min-width: 100%;
  border-radius: 2rem;
}

.join-us__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 7rem 5rem;
  min-height: 20rem;
}

.join-us__socials {
  display: flex;
  align-items: center;
}

.join-us__socials .button {
  width: 13.2rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-us__socials .button:not(:last-child) {
  margin-right: 1rem;
}

.join-us__socials .button img {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .join-us {
    margin-bottom: 6rem;
  }

  .join-us__container {
    max-width: 100%;
  }

  .join-us__wrapper {
    padding: 3rem 2rem;
  }

  .join-us .title {
    margin-bottom: 2rem;
  }

  .join-us__bg {
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .join-us .title {
    font-size: 2.8rem;
  }
}
