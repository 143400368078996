@import '../../public/fonts/GTWalsheimPro/stylesheet.css';
@import '../../public/fonts/NeuePixel/stylesheet.css';

@import 'reset.css';
@import 'normalize.css';

/* theme */
@import 'colors.css';
@import 'utils.css';

/* common.blocks */
@import '../components/@common.blocks/Layout/layout.css';
@import '../components/@common.blocks/Header/header.css';
@import '../components/@common.blocks/Footer/footer.css';
@import '../components/@common.blocks/Container/container.css';
@import '../components/@common.blocks/Logo/logo.css';
@import '../components/@common.blocks/Navigation/navigation.css';
@import '../components/@common.blocks/NavigationMobile/navigation-mobile.css';
@import '../components/@common.blocks/Button/button.css';
@import '../components/@common.blocks/Form/form.css';
@import '../components/@common.blocks/Input/input.css';
@import '../components/@common.blocks/Table/table.css';
@import '../components/@common.blocks/Title/title.css';
@import '../components/@common.blocks/Text/text.css';
@import '../components/@common.blocks/Avatar/avatar.css';
@import '../components/@common.blocks/Message/message.css';
@import '../components/@common.blocks/AssetCard/asset-card.css';
@import '../components/@common.blocks/AssetRankCard/asset-rank-card.css';
@import '../components/@common.blocks/AssetTraits/asset-traits.css';
@import '../components/@common.blocks/Search/search.css';
@import '../components/@common.blocks/Loader/loader.css';
@import '../components/@common.blocks/RangeSlider/range-slider.css';
@import '../components/@common.blocks/AssetsFilter/assets-filter.css';
@import '../components/@common.blocks/Select/select.css';
@import '../components/@common.blocks/FormField/form-field.css';
@import '../components/@common.blocks/Modal/modal.css';
@import '../components/@common.blocks/Radio/radio.css';
@import '../components/@common.blocks/CollectionCard/collection-card.css';
@import '../components/@common.blocks/Steps/steps.css';
@import '../components/@common.blocks/MenuBurger/menu-burger.css';
@import '../components/@common.blocks/Menu/menu.css';
@import '../components/@common.blocks/Checkbox/checkbox.css';
@import '../components/@common.blocks/Icon/icons.css';
@import '../components/@common.blocks/UpcomingCard/upcoming-card.css';
@import '../components/@common.blocks/TableCollections/table-collections.css';
@import '../components/@common.blocks/Divider/divider.css';
@import '../components/@common.blocks/RangePicker/range-picker.css';
@import '../components/@common.blocks/Pagination/pagination.css';
@import '../components/@common.blocks/CookieMessage/cookie-message.css';
@import '../components/@common.blocks/OnlineBadge/online-badge.css';
@import '../components/@common.blocks/Tabs/tabs.css';
@import '../components/@common.blocks/Card/card.css';
@import '../components/@common.blocks/Tag/tag.css';
@import '../components/@common.blocks/Badge/badge.css';
@import '../components/@common.blocks/Grid/grid.css';
@import '../components/@common.blocks/LatestCard/latest-card.css';
@import '../components/@common.blocks/Skeleton/skeleton.css';
@import '../components/@common.blocks/ActiveFilter/active-filter.css';

/* sections */
@import '../components/@sections/LoginForm/login-form.css';
@import '../components/@sections/ListingForm/listing-form.css';
@import '../components/@sections/PromoSection/promo-section.css';
@import '../components/@sections/TopCollections/top-collections.css';
@import '../components/@sections/Upcoming/upcoming.css';
@import '../components/@sections/PrefaceCollection/preface-collection.css';
@import '../components/@sections/CollectionAssets/collection-assets.css';
@import '../components/@sections/Profile/profile.css';
@import '../components/@sections/ProfileFavorites/profile-favorites.css';
@import '../components/@sections/ProfileCollections/profile-collections.css';
@import '../components/@sections/ProfileSettings/profile-settings.css';
@import '../components/@sections/ListingCollection/listing-collection.css';
@import '../components/@sections/DropsCalendar/drops-calendar.css';
@import '../components/@sections/CollectionsList/collections-list.css';
@import '../components/@sections/JoinUs/join-us.css';
@import '../components/@sections/RariGames/rari-games.css';
@import '../components/@sections/GameCollections/game-collections.css';
@import '../components/@sections/AboutSection/about-section.css';
@import '../components/@sections/PrefaceMint/preface-mint.css';
@import '../components/@sections/MintContent/mint-content.css';
@import '../components/@sections/LatestCollections/latest-collections.css';
@import '../components/@sections/MainStats/main-stats.css';
@import '../components/@sections/PrefaceMain/preface-main.css';
@import '../components/@sections/Rabbitzz/rabbitzz.css';
@import '../components/@sections/ChromeExtension/chrome-extension.css';
@import '../components/@sections/AssetPage/asset-page.css';
@import '../components/@sections/NFTWhitelist/nft-whitelist.css';

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-family: 'GT Walsheim Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--bg-dark);
  color: var(--text-color-default);
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: unset;
  color: inherit;
}

a:active,
a:focus {
  outline: none;
}

button,
input {
  outline: unset;
}

.section-main {
  position: relative;
  margin-bottom: 10rem;
}

@media (max-width: 768px) {
  .section-main {
    margin-bottom: 6rem;
  }
}
