.tabs.rc-tabs {
  position: relative;
  border: unset;
  overflow: unset;
}

.tabs .rc-tabs-tab {
  padding-bottom: 2rem;
  margin-right: 3rem;
  background: transparent;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: var(--white);
}

.tabs .rc-tabs-tab b {
  color: var(--text-color-light-gray);
  font-weight: 500;
}

.tabs .rc-tabs-nav {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.tabs.tabs_color_primary .rc-tabs-ink-bar {
  background: var(--plum);
}

.tabs.tabs_color_secondary .rc-tabs-ink-bar {
  background-color: var(--secondary-color);
}

.tab-pane {
  border: unset;
}

.tabs .rc-tabs-tabpane {
  padding-top: 4rem;
  padding-bottom: 1rem;
  outline: unset;
}
