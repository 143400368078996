.step {
  position: relative;
  overflow: hidden;
  flex: 1;
}

.step__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
}

.step__circle {
  position: relative;
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 50%;
  background-color: var(--dark-gray);
}

.step:not(:last-child) .step__circle:after {
  content: '';
  position: absolute;
  top: calc(50% - 0.1rem);
  left: calc(100% + 2rem);
  height: 0.2rem;
  width: 9999px;
  display: block;
  background-color: var(--dark-gray);
}

.step:not(:first-child) .step__circle:before {
  content: '';
  position: absolute;
  top: calc(50% - 0.1rem);
  right: calc(100% + 2rem);
  height: 0.2rem;
  width: 9999px;
  display: block;
  background-color: var(--dark-gray);
}

.step.step--finish .step__circle {
  border: 0.2rem solid var(--secondary-color);
}

.step.step--finish .step__circle:before {
  background-color: var(--secondary-color);
}

.step.step--finish .step__circle:after {
  background-color: var(--secondary-color);
}

.step__icon {
  width: 2.4rem;
  height: 2.4rem;
}

.step__title {
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.step.step--finish .step__title {
  color: var(--white);
}

/* vertical */

.steps_direction_vertical .step:not(:last-child) {
  margin-bottom: 20rem;
}

.steps_direction_vertical .step:not(:first-child) .step__circle:before {
  display: none;
}

.steps_direction_vertical .step:not(:last-child) .step__circle:after {
  display: none;
}

.steps_direction_vertical .step {
  overflow: unset;
}

.steps_direction_vertical .step:not(:last-child):after {
  content: '';
  position: absolute;
  top: calc(100% + 2rem);
  left: calc(50% - 0.1rem);
  height: 8rem;
  width: 0.2rem;
  display: block;
  background-color: var(--dark-gray);
}

.steps_direction_vertical .step:not(:first-child):before {
  content: '';
  position: absolute;
  left: calc(50% - 0.1rem);
  bottom: calc(100% + 2rem);
  height: 8rem;
  width: 0.2rem;
  display: block;
  background-color: var(--dark-gray);
}

.steps_direction_vertical .step.step--finish:not(:last-child):after {
  background-color: var(--secondary-color);
}

.steps_direction_vertical .step.step--finish:not(:first-child):before {
  background-color: var(--secondary-color);
}
