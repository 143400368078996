.navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation__link {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
  transition: color 0.3s;
}

.navigation__link:hover {
  color: var(--secondary-color);
}

.navigation_theme_light .navigation__link:hover {
  color: var(--secondary-color);
}

.navigation__link:not(:last-child) {
  margin-right: 3rem;
}
