.tag {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
}

.tag_color_default {
  padding: 0.7rem 1rem;
  border-radius: 0.7rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;
  background: rgba(255, 255, 255, 0.08);
  color: var(--white);
}

.tag_color_default img,
.tag_color_default .icon {
  width: 1.4rem;
  height: 1.4rem;
}
