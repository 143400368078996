.input-field {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-field--required label:after {
  content: ' *';
  position: relative;
  color: var(--secondary-color);
}

.input {
  height: 100%;
  padding: 1.2rem 2rem;
  border: unset;
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  transition: all 0.3s;
}

.input[type='date']::-webkit-inner-spin-button,
.input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  -moz-appearance: textfield;
}

.input-field_variant_default {
  border-radius: 1rem;
}

.input-field_variant_default .input {
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  border-radius: inherit;
  color: var(--white);
  background-color: transparent;
}

.input-field_variant_default:not(.input-field--disabled) .input:focus {
  border-color: var(--secondary-color);
}

.input__icon {
  position: absolute;
  top: calc(50% - 0.8rem);
  left: 2rem;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.7;
}

.input__icon + .input {
  padding-left: 4.6rem;
}

.input__clear {
  position: absolute;
  visibility: hidden;
  top: calc(50% - 1.7rem);
  right: 0.7rem;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 0.4rem;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: visibility 0.1s ease;
  cursor: pointer;
}

.input:focus + .input__clear {
  visibility: visible;
}

.input__clear > div {
  position: absolute;
  top: calc(50% - 0.7rem);
  left: calc(50% - 0.7rem);
  width: 1.4rem;
  height: 1.4rem;
  opacity: 0.4;
  z-index: 0;
}

.input__clear > div div {
  position: absolute;
  left: 50%;
  width: 0.2rem;
  height: 100%;
  border-radius: 1rem;
  background-color: #ffffff;
}

.input__clear > div div:nth-child(1) {
  transform: rotate(45deg);
}

.input__clear > div div:nth-child(2) {
  transform: rotate(-45deg);
}
