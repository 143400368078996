.form {
  position: relative;
  width: 55rem;
  max-width: 95%;
  margin: auto;
  border-radius: 2.5rem;
  background-color: var(--dark-gray);
}

.form__header {
  position: relative;
  height: 15rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 2.5rem;
  border-top-left-radius: 2.5rem;
  background-image: url('/images/form-header.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.form__header img {
  width: 6rem;
  height: 6rem;
}

.form__body {
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.form__title {
  margin-bottom: 5rem;
}

.form__title h2 {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.8rem;
  text-align: center;
}

.form__description {
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.form__description a {
  color: var(--white);
}

.form__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
}

@media (max-width: 768px) {
  .form__body {
    padding: 3rem;
  }
}
