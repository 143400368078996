.rabbitzz {
}

.rabbitzz__container {
  height: 42rem;
  background: #8c8edc;
  border-radius: 2rem;
}

.rabbitzz__container:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  height: 52rem;
  width: 52rem;
  background-color: transparent;
  background-image: url('/images/banners/rabbitzz.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.rabbitzz__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rabbitzz__bg:before {
  content: '';
  position: absolute;
  right: -10rem;
  top: -5rem;
  height: 65rem;
  width: 65rem;
  background-color: transparent;
  background-image: url('/images/banners/rabbitzz-club.png');
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.rabbitzz__wrapper {
  box-sizing: border-box;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}

.title.rabbitzz__title {
  max-width: 55rem;
  position: relative;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 6.4rem;
  line-height: 1;
  color: #ffe780;
  z-index: 1;
}

.rabbitzz__text {
  margin-bottom: 6rem;
  max-width: 55rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.25;
  z-index: 1;
}

.rabbitzz__links {
  display: flex;
  align-items: center;
  z-index: 1;
}

.rabbitzz__link:not(:last-child) {
  margin-right: 1rem;
}

.rabbitzz__link {
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.2rem solid var(--white);
  cursor: pointer;
}

.rabbitzz__links img {
  width: 1.8rem;
  height: 1.8rem;
  object-fit: contain;
}

@media (max-width: 1150px) {
  .rabbitzz__container {
    height: 38rem;
  }

  .rabbitzz__bg:before {
    height: 50rem;
    width: 50rem;
  }

  .rabbitzz__container:after {
    height: 44rem;
    width: 44rem;
  }

  .title.rabbitzz__title {
    font-size: 5rem;
  }
}

@media (max-width: 991px) {
  .rabbitzz__bg:before {
    left: calc(100% - 16rem);
    height: 50rem;
    width: 50rem;
  }

  .rabbitzz__container:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .rabbitzz__bg:before {
    display: none;
  }

  .rabbitzz__container {
    max-width: 100%;
    border-radius: 0;
  }

  .title.rabbitzz__title {
    font-size: 4rem;
  }

  .rabbitzz__text {
    margin-bottom: 4rem;
  }
}
