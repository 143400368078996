.message-container .message {
  padding: 2.2rem;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--white);
  background-color: var(--dark);
  border: 0.1rem solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
}

.message__close {
  position: relative;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  transition: opacity 0.2s;
}

.message__close:hover {
  opacity: unset;
}

.message__close div {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.1rem;
  background-color: #ffffff;
}

.message__close div:nth-child(1) {
  transform: rotate(45deg);
}

.message__close div:nth-child(2) {
  transform: rotate(-45deg);
}
