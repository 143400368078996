.asset-page {
  position: relative;
  margin-bottom: 8rem;
}

.asset-page__image .skeleton {
  position: relative;
  border-radius: 1.5rem;
  max-width: 100%;
  height: 55rem;
  width: 100%;
  overflow: hidden;
}

.asset-page__image img {
  object-fit: cover;
  width: 100%;
}

.asset-page__title {
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 4rem;
  line-height: 4.6rem;
}

.asset-page__rank {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.asset-page__rank-divider {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.asset-page__item {
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  background-color: var(--dark-gray);
}

.asset-page__item > .avatar {
  margin-right: 1rem;
}

.asset-page__traits > .text {
  margin-bottom: 1rem;
}

.asset-page__trait {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  border: 0.2rem solid var(--dark-gray);
}

.asset-page__trait-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: var(--dark-gray);
}

.asset-page__trait-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 1.9rem;
  font-size: 1.2rem;
  line-height: 2rem;
}

.asset-page__trait-score {
  display: flex;
  align-items: center;
}

.asset-page__trait-score img {
  margin-right: 0.1rem;
  width: 1.6rem;
  height: 1.6rem;
}

.asset-page__opensea {
  margin-top: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 0.2rem solid var(--plum);
  border-radius: 1rem;
  padding: 2.1rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--plum);
}

.asset-page__opensea .icon {
  margin-right: 1rem;
}

.asset-page__opensea path {
  fill: var(--plum);
}

.asset-page__back {
  display: none;
  align-items: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: var(--dark-gray);
  border-radius: 1rem;
}

.asset-page__back > img {
  margin-right: 1rem;
}

@media (max-width: 575px) {
  .asset-page__image .skeleton {
    height: 39rem;
  }

  .asset-page__back {
    display: flex;
  }
}
