.footer {
  position: relative;
  border-top: 0.1rem solid var(--dark-gray);
}

.footer__row {
  padding: 6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 0.1rem solid var(--dark-gray);
}

.footer__grid {
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
  grid-gap: 5rem;
}

.footer__navigation,
.footer__socials-links {
  margin-top: 2rem;
}

.footer__socials-link--disabled {
  cursor: not-allowed;
}

.footer__nav-link {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s;
}

.footer__nav-link:hover {
  color: var(--secondary-color);
}

.footer__navigation li:not(:last-child) {
  margin-bottom: 2rem;
}

.footer__socials-links {
  display: flex;
}

.footer__socials-link {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--dark-gray);
  transition: background-color 0.2s;
}

.footer__socials-link:not(:last-child) {
  margin-right: 2.5rem;
}

.footer__socials-link:hover {
  background-color: var(--light-gray);
}

.footer__logo {
  min-width: 10rem;
  margin-right: 6rem;
}

.footer__logo img {
  width: 10rem;
  height: 2rem;
}

.footer__bottom {
  padding: 4rem 0 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer__legal {
  display: flex;
  align-items: center;
}

.footer__legal span:not(:last-child) {
  margin-right: 2rem;
}

.footer__copyright,
.footer__legal {
  padding-bottom: 2rem;
}

.footer__legal > .text:hover {
  color: var(--text-color-gray-03);
}

@media (max-width: 768px) {
  .footer__row {
    flex-direction: column;
  }

  .footer__logo {
    margin-bottom: 4rem;
  }

  .footer__grid {
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid var(--dark-gray);
    grid-gap: 2rem;
  }

  .footer__socials {
    padding: 4rem 0;
  }

  .footer__row {
    padding-bottom: 0;
  }

  .footer__bottom {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
