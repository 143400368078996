:root {
  /* theme colors */
  --primary-color: #543bdf;
  --primary-color-hover: #472fca;
  /*--secondary-color: #f3fda5;*/
  /*--secondary-color-hover: #f3fda5;*/
  --secondary-color: #c7ace3;
  --secondary-color-hover: #c7ace3;
  --light-color: #ffffff;
  --light-color-hover: #e7e7e7;
  --white: #ffffff;
  --black: #000000;
  --dark: #202225;
  --dark-gray: #2c2d31;
  --gray: #3c3d42;
  --light-gray: #3b3c41;
  --orange: #f6851b;
  --gold: #fad273;
  --green: #56b157;
  --red: #e5785c;
  --purple: #897efa;
  --online: #08db82;
  --plum: #c7ace3;

  /* background */
  --bg-dark: #202225;

  /* text colors */
  --text-color-default: #ffffff;
  --text-color-primary: var(--primary-color);
  --text-color-secondary: var(--secondary-color);
  --text-color-dark: rgba(0, 0, 0, 0.6);
  --text-color-light-gray: rgba(255, 255, 255, 0.5);
  --text-color-gray-03: rgba(255, 255, 255, 0.3);

  /* button */
  --btn-default-border-color: rgba(255, 255, 255, 0.1);
}
