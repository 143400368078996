.logo {
  position: relative;
  width: 14.8rem;
  cursor: pointer;
  transition: opacity 0.3s;
}

.logo img {
  width: 14.8rem;
  height: 3.1rem;
  object-fit: contain;
}

.logo__container {
  display: flex;
  align-items: center;
}

.logo__container > span:first-child {
  margin-right: 1.5rem !important;
}

.logo.logo--short {
  width: 3rem;
}

.logo.logo--short img {
  width: 3rem;
  height: 3rem;
}

.logo:hover {
  opacity: 0.7;
}
