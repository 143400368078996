.table-collections {
  position: relative;
}

.table-collections__logo {
  display: flex;
  align-items: center;
}

.table-collections__logo .avatar,
.table-collections__logo .skeleton-avatar {
  margin: 0 2rem;
}

.table-collections__logo > .text {
  min-width: 3ch;
}

.table-collections__logo > .text:first-child {
  padding-left: 1rem;
}

.table-collections__name {
  display: flex;
  flex-direction: column;
  min-width: 23ch;
}

.table-collections__floor {
  display: none;
}

.table-collections__verified {
  margin-left: 0.6rem;
}

.table-collections__stats-field {
  position: relative;
  display: flex;
  align-items: center;
}

.table-collections__stats-field > .text:first-child {
  display: flex;
  align-items: center;
}

.table-collections__stats-field img {
  margin-right: 0.5rem !important;
  opacity: 0.4;
}

.table-collections__stats-percent {
  position: absolute;
  top: 100%;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

@media (max-width: 991px) {
  .table-collections .table-head .title {
    margin-bottom: 3rem;
  }
}

@media (max-width: 768px) {
  .table-collections__floor {
    display: unset;
  }

  .table-collections__stats-field {
    align-items: flex-end;
    flex-direction: column;
  }

  .table-collections__stats-percent {
    position: relative;
  }

  .table-collections__logo > .text:first-child {
    padding-left: 0;
  }
}

@media (max-width: 575px) {
  .table-collections__logo .avatar,
  .table-collections__logo .skeleton-avatar {
    margin: 0 1rem;
  }
}
