.checkbox-wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  line-height: unset;
  cursor: pointer;
  font-size: 1.6rem;
}

.checkbox {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.checkbox__inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 2rem;
  height: 2rem;
  direction: ltr;
  background-color: transparent;
  border: 0.2rem solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  border-collapse: separate;
  transition: all 0.3s;
}

.checkbox + span {
  padding: 0 1rem;
}

.checkbox:hover:after,
.checkbox-wrapper:hover .checkbox:after {
  visibility: visible;
}

.checkbox--checked:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.2rem solid var(--secondary-color);
  border-radius: 0.3rem;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.checkbox--checked .checkbox__inner:after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 21.5%;
  height: 0.9rem;
  width: 0.6rem;
  display: table;
  border: 0.2rem solid var(--secondary-color);
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.checkbox--checked .checkbox__inner {
  border: 0.2rem solid var(--secondary-color);
}
