@import './search-mobile.css';
@import './search-result.css';

.search {
  position: relative;
  z-index: 97;
}

.search__input-group {
  position: relative;
  background-color: rgba(255, 255, 255, 0.07);
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search__input-clear {
  position: absolute;
  visibility: hidden;
  top: calc(50% - 2.2rem);
  right: 1.3rem;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: visibility 0.1s ease;
  cursor: pointer;
}

.search__input-group--active .search__input-clear {
  visibility: visible;
}

.search__input-clear > div {
  position: absolute;
  top: calc(50% - 0.8rem);
  left: calc(50% - 0.8rem);
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  z-index: 0;
}

.search__input-clear > div div {
  position: absolute;
  left: 50%;
  width: 0.2rem;
  height: 100%;
  border-radius: 1rem;
  background-color: #ffffff;
}

.search__input-clear > div div:nth-child(1) {
  transform: rotate(45deg);
}

.search__input-clear > div div:nth-child(2) {
  transform: rotate(-45deg);
}

.search_shape_circle .search__input-clear {
  background-color: transparent;
}

.search_shape_circle .search__input-group {
  border-radius: 4.4rem;
  padding: 1.2rem 3rem;
}

.search_shape_square .search__input-group {
  border-radius: 1.6rem;
  padding: 2.4rem 3rem;
}

.search__input-group img {
  margin-right: 1rem;
  opacity: 0.5;
}

.search__input {
  width: 100%;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border: none;
  transition: color 0.3s;
}

.search__input-group--active {
  background-color: rgba(255, 255, 255, 0.15);
}

.search_shape_square .search__input-group {
  background-color: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transform: translate3d(0, 0, 0);
}

.search_shape_square .search__input-group--active {
  background-color: rgba(255, 255, 255, 0.15);
}

.search__input-group--active img {
  opacity: 1;
}

.search__input:focus {
  color: var(--white);
}
