.assets-filter {
  position: sticky;
  top: 8rem;
  bottom: 0;
  border-radius: 1rem;
  height: calc(100vh - 10rem);
  overflow: auto;
}

.assets-filter__divider {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.assets-filter__form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow-y: auto;
}

.assets-filter__form::-webkit-scrollbar {
  width: 0;
}

.assets-filter__form > .form-field {
  margin-bottom: 2rem;
}

.assets-filter__traits {
  display: flex;
  flex-direction: column;
}

.assets-filter__traits-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-transform: capitalize;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  color: var(--white);
  background: var(--dark-gray);
  border-radius: 1rem;
}

.assets-filter__traits-item:not(:last-child) {
  margin-bottom: 1rem;
}

.assets-filter__trait {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 1.6rem;
}

.assets-filter__trait > span {
  font-weight: 500;
  font-size: 1.6rem;
}

.assets-filter__trait img {
  transition: all 0.2s;
}

.assets-filter__traits-item--open .assets-filter__trait img {
  transform: rotate(180deg);
}

.assets-filter__trait-props {
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.2s ease-in-out;
}

.assets-filter__trait-props--open {
  max-height: 30rem;
}

.assets-filter__trait-props li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
  margin-bottom: 1rem;
}

.assets-filter__trait-props .checkbox-wrapper {
  font-size: 1.5rem;
}

.assets-filter__toggle-mobile {
  width: 100%;
  text-align: left;
  border-color: var(--secondary-color);
}

.assets-filter__toggle-mobile img {
  margin-right: 1rem;
}

.assets-filter-mobile {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--dark);
  z-index: 101;
}

.assets-filter__header {
  margin-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assets-filter__close-btn {
  position: relative;
  width: 2rem;
  height: 2rem;
}

.assets-filter__close-btn div {
  position: absolute;
  top: calc(50% - 0.1rem);
  width: 100%;
  height: 0.2rem;
  background-color: var(--white);
}

.assets-filter__close-btn div:nth-child(1) {
  transform: rotate(45deg);
}

.assets-filter__close-btn div:nth-child(2) {
  transform: rotate(-45deg);
}

.assets-filter__form-submit {
  margin-top: 4rem;
  margin-bottom: 10rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
}

.assets-filter__form-submit .button {
  width: 100%;
}

.assets-filter__form-range {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  display: inline-block;
  align-items: center;
  justify-content: space-between;
}

.assets-filter__form-range .input-field {
  flex: 1;
}

@media (max-width: 768px) {
  .assets-filter__header {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .assets-filter__form {
    position: absolute;
    top: 10rem;
    bottom: 9rem;
    width: 100%;
    padding: 0 2rem;
  }

  .assets-filter__form-submit {
    margin: 0;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 2rem;
  }
}
