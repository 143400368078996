.skeleton-input {
  position: relative;
  width: 100%;
  height: 2rem;
  border-radius: 2rem;
  margin-right: 2rem;
  overflow: hidden;
}

.skeleton-input.skeleton-input--loading {
  pointer-events: none;
  user-select: none;
  background: padding-box #3b3c41;
  width: 100%;
}

.skeleton-input.skeleton-input--loading *,
.skeleton-input.skeleton-input--loading:before,
.skeleton-input.skeleton-input--loading:after {
  visibility: hidden;
}

.skeleton-input.skeleton-input--active {
  -webkit-animation: skeleton-active 1.4s ease;
  -webkit-animation-iteration-count: infinite;
}
