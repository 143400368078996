@import './step.css';

.steps {
  position: relative;
  width: 100%;
  display: flex;
}

.steps_direction_vertical {
  flex-direction: column;
}
