.collection-card {
  position: relative;
  border-radius: 2.5rem;
  width: 40rem;
  max-width: 100%;
  cursor: pointer;
  background-color: var(--dark-gray);
}

.collection-card__cover-wrapper {
  position: relative;
  height: 12rem;
  width: 100%;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  background-color: #414246;
  background-image: url('/images/placeholder.svg');
  background-repeat: no-repeat;
  background-size: 18rem;
  background-position: center;
  z-index: 2;
}

.collection-card__cover {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  object-fit: cover;
}

.collection-card__container {
  position: relative;
  padding: 3rem;
  z-index: 3;
}

.collection-card__logo {
  position: absolute;
  top: -4rem;
  left: calc(50% - 4rem);
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 0.3rem solid var(--dark-gray);
  background-color: var(--light-gray);
}

.collection-card__logo img {
  border-radius: 50%;
  object-fit: cover;
}

.collection-card__title {
  margin: 2rem 0;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: center;
  color: var(--white);
}
