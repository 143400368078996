.rari-games {
  position: relative;
  margin-bottom: 10rem;
}

.rari-games__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 20rem;
  padding: 7rem 5rem;
  border-radius: 2rem;
}

.rari-games__bg {
  z-index: -1;
  object-fit: cover;
  object-position: center calc(50% - 5rem);
  width: 100%;
  min-width: 100%;
  border-radius: 2rem;
}

.rari-games__wrapper .button {
  width: 19rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

@media (max-width: 768px) {
  .rari-games {
    margin-bottom: 7rem;
  }

  .rari-games__container {
    max-width: 100%;
  }

  .rari-games__wrapper {
    padding: 3rem 2rem;
    border-radius: 0;
    min-height: 16rem;
  }

  .rari-games__bg {
    border-radius: 0;
    object-position: center;
  }

  .rari-games .title {
    margin-bottom: 2rem;
  }
}
