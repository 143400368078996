.preface-collection {
  margin-bottom: 10rem;
}

.preface-collection__cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30rem;
  z-index: -1;
}

.preface-collection__cover img {
  object-fit: cover;
}

.preface-collection__cover .skeleton > span::before {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.preface-collection__content {
  margin-top: 24rem;
}

.preface-collection__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65rem;
}

.preface-collection__logo {
  margin-bottom: 2rem;
}

h2.preface-collection__title {
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.8rem;
}
.preface-collection__container > .text.text_size_md {
  font-size: 1.6rem;
  line-height: 2rem;
}

.preface-collection__verified {
  position: relative;
  margin-left: 1rem;
  width: 1.8rem;
  height: 1.8rem;
}

.preface-collection__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
}

.preface-collection__contract {
  padding: 1.1rem 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-items: center;
  align-items: center;
  border-radius: 4rem;
  background-color: var(--dark-gray);
  transition: background-color 0.2s ease;
}

.preface-collection__contract:hover {
  background-color: var(--light-gray);
}

.preface-collection__contract img {
  margin-right: 1rem;
  width: 1.8rem;
  height: 1.8rem;
}

.preface-collection__socials a:not(:last-child) {
  margin-right: 2rem;
}

.preface-collection__stats {
  padding: 4rem;
  width: 89rem;
  box-sizing: border-box;
  border-radius: 2.5rem;
  background-color: var(--dark-gray);
}

.preface-collection__stats-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.preface-collection__stats-field {
  display: flex;
  align-items: center;
}

.preface-collection__stats-field img {
  margin-right: 0.4rem !important;
  opacity: 0.4;
}

@media (max-width: 768px) {
  .preface-collection {
    margin-bottom: 8rem;
  }

  .preface-collection__banner {
    margin-bottom: 6.5rem;
  }

  .preface-collection__banner > img {
    border-radius: unset;
  }

  .preface-collection__logo {
    top: calc(100% - 5rem);
    left: calc(50% - 5rem);
    width: 10rem;
    height: 10rem;
  }

  .preface-collection__logo .avatar.avatar_size_xl {
    width: 10rem;
    height: 10rem;
    min-width: 10rem;
    min-height: 10rem;
  }

  .preface-collection__logo .avatar_size_xl .avatar__blueMark {
    width: 2rem;
    height: 2rem;
  }

  .preface-collection__stats {
    padding: 0;
    background-color: transparent;
    width: 50rem;
  }

  .preface-collection__stats-grid {
    position: relative;
    box-sizing: content-box;
    display: grid;
    grid-gap: 1.5rem;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
  }

  .preface-collection__stats-grid:before {
    content: '';
    position: absolute;
    top: calc(50% - 0.1rem);
    height: 0.2rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .preface-collection__stats .preface-collection__stats-border {
    position: absolute;
    left: calc(50% + 0.1rem);
    width: 0.2rem;
    height: calc(50% - 0.3rem);
    background-color: rgba(255, 255, 255, 0.1);
  }
  .preface-collection__stats .preface-collection__stats-border:nth-child(1) {
    top: 0.2rem;
  }

  .preface-collection__stats .preface-collection__stats-border:nth-child(2) {
    bottom: 0.2rem;
  }

  .preface-collection__stats-grid div {
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .preface-collection__stats-grid div:first-child {
    display: none;
  }

  .preface-collection__stats-grid div .preface-collection__stats-name {
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  .preface-collection__stats-grid div .preface-collection__stats-field {
    font-size: 1.6rem;
  }
}
