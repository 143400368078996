.select {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
}

.select select {
  box-sizing: border-box;
  padding: 1rem 2rem;
  background: transparent;
  border: unset;
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  line-height: 2rem;
  outline: none;
  cursor: pointer;
  color: var(--white);
  -webkit-appearance: none;
  -moz-appearance: none;
}
