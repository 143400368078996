.upcoming-card-mobile {
}

.upcoming-card-mobile__footer {
}

.upcoming-card-mobile__grid {
  display: flex;
  flex-wrap: wrap;
}

.upcoming-card-mobile__header .upcoming-card-mobile__grid {
  margin-bottom: 0.9rem;
}

.upcoming-card-mobile__header .tag img {
  margin-right: 0.6rem !important;
  opacity: 0.4;
}

.upcoming-card-mobile__grid-item {
  margin-bottom: 0.6rem;
}

.upcoming-card-mobile__grid-item:not(:last-child) {
  margin-right: 0.6rem;
}

.upcoming-card-mobile__name {
  margin-bottom: 1.5rem;
}

.upcoming-card-mobile__subtitle {
  margin-bottom: 1rem;
}
