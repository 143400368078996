.game-collections {
  position: relative;
  margin-bottom: 13rem;
}

.game-collections .table-head {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-gap: 2rem;
}

.game-collections__button {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.game-collections__filter {
  display: grid;
  grid-template-columns: repeat(3, 12.6rem);
  justify-content: right;
  grid-gap: 1rem;
  width: 100%;
}

@media (max-width: 991px) {
  .game-collections__filter {
    justify-content: left;
  }
}

@media (max-width: 768px) {
  .game-collections__filter {
    grid-template-columns: repeat(3, 10.6rem);
  }
}
