@import './skeleton-avatar.css';
@import './skeleton-input.css';

.skeleton {
}

.skeleton.skeleton--loading {
  pointer-events: none;
  user-select: none;
  background: padding-box #3b3c41;
  width: 100%;
  height: 100%;
}

.skeleton.skeleton--loading *,
.skeleton.skeleton--loading:before,
.skeleton.skeleton--loading:after {
  visibility: hidden;
}

.skeleton.skeleton--active {
  -webkit-animation: skeleton-active 1.4s ease;
  -webkit-animation-iteration-count: infinite;
}

@keyframes skeleton-active {
  0% {
    background: #3b3c41;
  }
  50% {
    background: #5d5e65;
  }
  100% {
    background: #3b3c41;
  }
}
