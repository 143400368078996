.main-stats {
  position: relative;
  margin-bottom: 10rem;
}

.main-stats__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
  background: var(--dark-gray);
  border-radius: 20px;
}

.main-stats__item {
  flex: 1;
  padding: 1.8rem;
  box-sizing: content-box;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 2rem;
  min-width: 25rem;
  max-width: 25.6rem;
}

.main-stats__item-img {
  object-fit: contain;
}

@media (max-width: 575px) {
  .main-stats__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .main-stats__item:not(:last-child) {
    margin-bottom: 3rem;
  }
}
