@import './menu-item.css';

.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  background: var(--dark-gray);
  min-width: 25rem;
  border-radius: 1rem;
}
