.range-picker {
  position: relative;
  display: flex;
  align-items: center;
}

.range-picker .range-picker__input {
  width: 8rem;
  margin-top: 1rem;
}

.range-picker .range-picker__input .input {
  padding: 0.5rem 1.2rem;
}

.range-picker__label {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.5);
}

.range-picker .range-picker__label:first-child .range-picker__input {
  margin-right: 2rem;
}

.range-picker .range-picker__label:first-child .range-picker__input:before {
  content: '';
  position: absolute;
  right: -1.3rem;
  top: calc(50% - 0.3rem);
  width: 0.6rem;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.1);
}
