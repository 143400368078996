.text {
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1.6rem;
  line-height: 1.44;
  transition: color 0.2s;
}

.text_color_default {
  color: var(--text-color-default);
}

.text_color_primary {
  color: var(--text-color-primary);
}

.text_color_secondary {
  color: var(--text-color-secondary);
}

.text_color_dark {
  color: var(--dark);
}

.text_color_white {
  color: var(--white);
}

.text_color_light-gray {
  color: var(--text-color-light-gray);
}

.text_color_gray-03 {
  color: var(--text-color-gray-03);
}

.text_color_positive {
  color: var(--green);
}

.text_color_negative {
  color: var(--red);
}

.text_color_gradient {
  background: linear-gradient(91.01deg, #7ba2ff 0%, #ff9df5 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_color_gold {
  color: var(--gold);
}

.text_color_plum {
  color: var(--plum);
}

.text_color_rainbow {
  background: linear-gradient(
      94.58deg,
      #00ff57 10.18%,
      #fec2fe 42.8%,
      #ffe233 71.59%
    ),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_size_xs {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text_size_sm {
  font-size: 1.4rem;
  line-height: 2rem;
}

.text_size_md {
  font-size: 1.6rem;
  line-height: 1.44;
}

.text_size_lg {
  font-size: 2rem;
  line-height: 1.1;
}

.text_size_xl {
  font-size: 2.4rem;
  line-height: 2.7rem;
}

.text_size_inherit {
  font-size: inherit;
  line-height: inherit;
}

.text_weight_medium {
  font-weight: 500;
}

.text_weight_semi-bold {
  font-weight: 600;
}

.text_weight_bold {
  font-weight: 700;
}

/* utils */
.text-center,
.title.text-center,
.text.text-center {
  text-align: center;
}

.text-left,
.title.text-left,
.text.text-left {
  text-align: left;
}

.text-underline {
  text-decoration: underline;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-break-word {
  word-break: break-word;
}

@media (max-width: 768px) {
  .text_size_md {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}
