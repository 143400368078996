.profile-collections {
  position: relative;
}

.profile-collections__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 40rem));
  grid-gap: 5rem;
}

@media (max-width: 768px) {
  .profile-collections__grid {
    grid-gap: 2rem;
  }
}
