.collection-assets {
  position: relative;
}

.collection-assets__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8rem;
  height: 100%;
}

.collection-assets__filter-container {
  position: sticky;
  top: 8rem;
  padding: 2rem 0;
  z-index: 1;
  background-color: var(--bg-dark);
}

.collection-assets__active-filter {
  position: sticky;
  top: 8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  z-index: 1;
}

.collection-assets__clear-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: unset;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.collection-assets__active-filter > .input-field {
  width: 30rem;
  max-width: 100%;
}

.collection-assets__active-filter li:not(:last-child) {
  height: 4.4rem;
  margin-right: 1rem;
}

.collection-assets__active-filter li {
  position: relative;
  height: 4.4rem;
  margin-bottom: 1rem;
}

.collection-assets__active-filter li > div {
  height: 100%;
}

@media (max-width: 768px) {
  .collection-assets__header {
    margin-bottom: 3rem;
  }

  .collection-assets__filter-container {
    top: 7rem;
    padding-bottom: 0;
  }

  .collection-assets__active-filter .collection-assets__active-filter-search {
    width: 100%;
  }
}
