.menu-burger {
  position: relative;
  display: flex;
  align-items: center;
  width: 2.8rem;
  height: 2rem;
  box-sizing: border-box;
  z-index: 99;
}

.menu-burger__container {
  position: relative;
  height: 1.4rem;
  width: 100%;
}

.menu-burger__container div {
  position: absolute;
  height: 0.2rem;
  width: 100%;
  display: block;
  background-color: #ffffff;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  will-change: transform;
}

.menu-burger__container div:nth-child(1) {
  top: 0;
}

.menu-burger__container div:nth-child(2) {
  top: calc(50% - 0.1rem);
}

.menu-burger__container div:nth-child(3) {
  bottom: 0;
}

.menu-burger.menu-burger--open .menu-burger__container div:nth-child(1) {
  top: 0.8rem;
  transform: rotate(45deg);
  background-color: rgba(255, 255, 255, 0.7);
}

.menu-burger.menu-burger--open .menu-burger__container div:nth-child(2) {
  display: none;
}

.menu-burger.menu-burger--open .menu-burger__container div:nth-child(3) {
  top: 0.8rem;
  transform: rotate(-45deg);
  background-color: rgba(255, 255, 255, 0.7);
}
