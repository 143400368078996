.profile-favorites {
  position: relative;
}

.profile-favorites__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 19rem);
  grid-gap: 3rem;
}

@media (max-width: 768px) {
  .profile-favorites__grid {
    grid-template-columns: repeat(auto-fit, 17.4rem);
    grid-gap: 2rem;
  }
}
