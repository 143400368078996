.listing-collection {
  position: relative;
}

.listing-collection__title {
  margin: 10rem 0;
}

.listing-collection__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
}

.listing-collection__review {
  margin: 10rem auto;
  padding: 3rem;
  width: 45rem;
  max-width: 95%;
  background-color: var(--dark-gray);
  border-radius: 1rem;
}

.listing-collection .table-head__extra {
  flex: unset;
}

.listing-collection__table-td {
  display: flex;
  align-items: center;
}

.listing-collection__table-td .avatar {
  margin: 0 1rem;
}

.listing-collection__published {
  background-color: var(--gray);
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-radius: 2.5rem;
}

.listing-collection__published > div {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
