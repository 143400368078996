.profile {
  position: relative;
}

.profile__wrapper {
  position: relative;
  display: flex;
}

.profile__menu {
  position: sticky;
  top: 5rem;
  margin-right: 5rem;
}

.profile__content {
  flex: 1;
}

@media (max-width: 768px) {
  .profile {
    margin-bottom: 10rem;
  }

  .profile__wrapper {
    flex-direction: column;
  }

  .profile__menu {
    position: relative;
    top: 0;
    margin-right: 0;
    margin-bottom: 5rem;
  }
}
