.menu-item {
  display: inline-flex;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  color: var(--white);
  transition: all 0.2s;
}

.menu-item--active {
  background: rgba(255, 255, 255, 0.1);
}

.menu-item--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.menu-item span {
  padding: 0 1rem;
}

.menu-item__icon {
  margin-right: 1rem;
}
