.cookie-message {
  position: fixed;
  left: calc(50% - 19.45rem);
  bottom: 2rem;
  visibility: hidden;
  width: 38.9rem;
  max-width: 100%;
  border-radius: 5rem;
  background-color: var(--dark-gray);
  z-index: 97;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.cookie-message--show {
  opacity: 1;
  visibility: visible;
}

.cookie-message__wrapper {
  box-sizing: border-box;
  padding: 0.4rem 0.4rem 0.4rem 2rem;
  display: flex;
  align-items: center;
}

.cookie-message__text {
  flex: 1;
  font-size: 1.5rem;
  line-height: 1.9rem;
  margin-right: 1rem;
}

.cookie-message__text a {
  text-decoration: underline;
}

.cookie-message__button {
  width: 9.7rem;
  height: 4.2rem;
  background: #f1f1f1;
  border-radius: 5rem;
  border: unset;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
}
