.upcoming {
  position: relative;
  margin-bottom: 10rem;
}

.upcoming .section__title {
  margin-bottom: 5rem;
}

.upcoming__link {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.upcoming__link .button {
  min-width: 16rem;
}

@media (max-width: 768px) {
  .upcoming {
    margin-bottom: 6rem;
  }
}

@media (max-width: 575px) {
  .upcoming__link .button {
    width: 100%;
  }
}
