@import './radio-button.css';
@import './radio-group.css';

.radio-wrapper {
  position: relative;
  display: inline-flex;
  margin-bottom: 1rem;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  font-size: 1.6rem;
  width: 100%;
  color: var(--white);
}

.radio {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.radio-input {
  position: absolute;
  width: 2.6rem;
  height: 2.6rem;
  inset: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 2.6rem;
  height: 2.6rem;
  background-color: transparent;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.3s;
}

.radio-inner:after {
  content: '';
  top: calc(50% - 0.4rem);
  left: calc(50% - 0.4rem);
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
  border: 0.2rem solid transparent;
  border-radius: 50%;
  transition: all 0.3s;
}

.radio--checked .radio-inner,
.radio--checked .radio-inner:after {
  border-color: var(--secondary-color);
}

span.radio + * {
  padding: 0 1rem;
}
