.asset-traits {
  position: relative;
}

.asset-traits__title {
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 0.1);
}

.asset-traits__traits {
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
  max-height: 33rem;
}

.asset-traits__traits::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.asset-traits__row {
  padding: 1.2rem 0;
}

.asset-traits__trait-type,
.asset-traits__trait-value {
  text-transform: capitalize;
}

.asset-traits__trait-values {
  display: flex;
  justify-content: space-between;
}

.asset-traits__trait-score {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.asset-traits__trait-score img {
  margin-right: 0.5rem;
}

.asset-traits__trait-values div {
  display: flex;
}

.asset-traits__indicator {
  position: relative;
  margin-top: 1.2rem;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.asset-traits__indicator div {
  position: absolute;
  left: 0;
  top: 0;
  height: 0.2rem;
  background-color: var(--secondary-color);
}
