@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro Black'), local('GTWalsheimPro-Black'),
    url('GTWalsheimPro-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
    url('GTWalsheimPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro SemiBold'), local('GTWalsheimPro-SemiBold'),
    url('GTWalsheimPro-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
    url('GTWalsheimPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: local('Euclid Circular A Regular'), local('GTWalsheimPro-Regular'),
    url('GTWalsheimPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
