.asset-card {
  position: relative;
  box-sizing: border-box;
  border-radius: 1.5rem;
  max-height: 32rem;
  background-color: var(--dark-gray);
  cursor: pointer;
  transition: all 0.1s ease 0s;
}

.asset-card:hover {
  transform: translate(0, -0.3rem);
}

.asset-card__cover {
  position: relative;
  width: 100%;
  height: 19rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  overflow: hidden;
}

.asset-card__cover img {
  object-fit: cover;
}

.asset-card__container {
  padding: 0 1rem;
  box-sizing: border-box;
}

.asset-card__header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.asset-card__footer {
  padding: 2rem 0;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.asset-card__favorite {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.4rem;
}

.asset-card__favorite img {
  width: 100%;
  object-fit: contain;
}

.asset-card__footer-buy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
}

.asset-card__footer-buy img {
  width: 1rem;
  margin-right: 0.4rem !important;
  opacity: 0.7;
}

.asset-card__skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--light-gray);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.asset-card__skeleton:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(129, 129, 129, 0.24) 37%,
      rgba(255, 255, 255, 0) 63%
    )
    0 0 / 400% 100%;
  animation: 1.2s ease 0s infinite normal none running asset-skeleton-loading;
}

.asset-card__header .asset-card__skeleton {
  height: 2rem;
  min-width: 12rem;
  max-width: 12rem;
  border-radius: 1.5rem;
}

.asset-card__footer .asset-card__skeleton {
  height: 2rem;
  min-width: 12rem;
  max-width: 12rem;
  border-radius: 1.5rem;
}

.asset-card__header .asset-card__skeleton:before,
.asset-card__footer .asset-card__skeleton:before {
  border-radius: 1.5rem;
}

.asset-card--loading .asset-card__footer {
  border-color: transparent;
  display: flex;
}

@keyframes asset-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.asset-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.asset-placeholder > div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background-color: var(--light-gray);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

img.asset-placeholder__logo {
  position: absolute;
  top: calc(50% - 3.5rem);
  left: calc(50% - 3.5rem);
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
}

@media (max-width: 768px) {
  .asset-card {
    border-radius: 0.6rem;
  }

  .asset-card__cover {
    height: 17.4rem;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }

  /*.asset-card__cover-img {*/
  /*  border-top-left-radius: 0.6rem;*/
  /*  border-top-right-radius: 0.6rem;*/
  /*}*/
}

@media (max-width: 414px) {
  .asset-card__cover {
    height: 16.4rem;
  }
}
