.preface-main {
  margin-bottom: 16rem;
  padding-top: 3rem;
}

.title.preface-main__title {
  font-family: 'NeuePixel', sans-serif;
  text-transform: uppercase;
  margin: 0 0 6rem;
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1.03;
  text-align: center;
}

.title.preface-main__title > span {
  font-family: inherit;
  font-weight: inherit;
}

.preface-main__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preface-main__cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 36rem;
  z-index: 0;
}

.preface-main__cover:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(32, 34, 37, 0.8) 0%,
    #202225 100%,
    #202225 100%
  );
  z-index: 1;
}

.preface-main__cover:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;
  background: linear-gradient(
    180deg,
    rgba(199, 172, 227, 0.2) 0%,
    rgba(199, 172, 227, 0) 100%
  );
  z-index: 2;
}

.preface-main__bg {
  object-fit: cover;
  object-position: top center;
}

.preface-main__container .search {
  width: 55rem;
  max-width: 100%;
  margin: auto auto 2rem;
}

@media (max-width: 768px) {
  .preface-main {
    padding-top: 10rem;
  }

  .title.preface-main__title {
    margin-bottom: 4rem;
    font-size: 3.7rem;
    line-height: 1.2;
  }
}
