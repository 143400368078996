.container {
  position: relative;
  max-width: 90%;
  margin: 0 auto;
}

.container_size_md {
  width: 115rem;
}

.container_size_sm {
  width: 75rem;
}

.container_size_xs {
  width: 65rem;
}
