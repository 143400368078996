@import './upcoming-card-mobile.css';

.upcoming-card {
  position: relative;
  max-width: 100%;
  max-height: 46rem;
  border-radius: 1rem;
  background-color: var(--dark-gray);
  overflow: hidden;
  transition: all 0.1s ease 0s;
}

.upcoming-card:hover {
  transform: translate(0, -0.3rem);
}

.upcoming-card__blockchain,
.upcoming-card__promo {
  position: absolute;
  top: 1rem;
  display: flex;
  align-items: center;
  padding: 0.9rem 1.4rem;
  box-sizing: border-box;
  border-radius: 4rem;
  font-size: 1.2rem;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.upcoming-card__blockchain {
  right: 1rem;
}

.upcoming-card__promo {
  left: 1rem;
}

.upcoming-card__blockchain img {
  margin-right: 0.4rem !important;
}

.upcoming-card__promo img {
  margin-right: 1rem;
}

.upcoming-card__cover {
  position: relative;
  width: 100%;
  height: 25rem;
}

.upcoming-card__cover img {
  object-fit: cover;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.upcoming-card__container {
  position: relative;
  padding: 2rem;
  box-sizing: border-box;
}

.upcoming-card__header {
}

.upcoming-card__metadata {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.upcoming-card__metadata-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.upcoming-card__metadata-item {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.upcoming-card__metadata-item:first-child {
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.upcoming-card__footer {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.upcoming-card__footer a:not(:last-child) {
  margin-right: 2rem;
}

.upcoming-card__footer .icon {
  opacity: 0.5;
  transition: opacity 0.1s ease;
}

.upcoming-card__footer .icon:hover {
  opacity: 1;
}

.upcoming-card__footer .icon:hover path {
  opacity: 1;
  fill: var(--white);
}

.upcoming-card__metadata-item .text {
  display: flex;
  align-items: center;
}

.upcoming-card__metadata-item .text img {
  margin-right: 0.4rem !important;
}

.upcoming-card__status {
  position: absolute;
  bottom: 0.2rem;
  left: 0.2rem;
  right: 0.2rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.upcoming-card__status--mint {
  position: relative;
  padding: 1.2rem 0;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--white);
}

.upcoming-card__online {
  position: absolute;
  right: calc(100% + 0.6rem);
  top: calc(50% - 0.4rem);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: var(--online);
  -webkit-animation: pulsate 3s ease;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .upcoming-card__container {
    padding: 1rem;
  }

  .upcoming-card__footer {
    margin-top: 1rem;
  }

  .upcoming-card__cover {
    height: 17.4rem;
  }
}
