.rounded-none {
  border-radius: unset;
}

.rounded-1 {
  border-radius: 1rem;
}

.rounded-2 {
  border-radius: 2rem;
}

@media (max-width: 575px) {
  .rounded-xs-none {
    border-radius: unset;
  }

  .rounded-xs-1 {
    border-radius: 1rem;
  }

  .rounded-xs-2 {
    border-radius: 2rem;
  }
}
