.asset-rank-card {
  position: relative;
  display: flex;
  max-width: 100%;
  width: 80rem;
}

.asset-rank-card__grid {
  display: grid;
  grid-gap: 2rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'assetRankCardImage assetRankCardContent'
    'assetRankCardName assetRankCardContent'
    'assetRankCardOpensea assetRankCardContent';
}

.asset-rank-card__grid-item {
  display: flex;
  flex-direction: column;
  grid-area: assetRankCardContent;
  max-width: 100%;
}

.asset-rank-card__image {
  position: relative;
  height: 37rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  overflow: hidden;
  grid-area: assetRankCardImage;
}

.asset-rank-card__image img {
  object-fit: cover;
}

.asset-rank-card__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  grid-area: assetRankCardName;
}

.asset-rank-card__header {
  margin-bottom: 2rem;
  padding: 1.9rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background-color: var(--gray);
}

.asset-rank-card__rank {
  margin-bottom: 0.4rem;
  font-size: 3rem;
  line-height: 1;
}

.asset-rank-card__opensea {
  padding: 1.2rem 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  grid-area: assetRankCardOpensea;
}

.asset-rank-card__opensea img {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .asset-rank-card {
    width: 45rem;
  }

  .asset-rank-card__image,
  .asset-rank-card__grid-item {
    width: 100%;
  }

  .asset-rank-card__grid-item {
    height: unset;
  }

  .asset-rank-card__grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'assetRankCardImage'
      'assetRankCardContent'
      'assetRankCardName'
      'assetRankCardOpensea';
  }
}

@media (max-width: 575px) {
  .asset-rank-card {
    width: 30rem;
  }

  .asset-rank-card__image {
    height: 30rem;
  }
}
