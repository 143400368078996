.profile-settings {
  position: relative;
}

.profile-settings__form {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 2.5rem;
  background-color: var(--dark-gray);
}

.profile-settings__form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 3rem;
  width: 100%;
}

.profile-settings__wallet {
  margin-top: 3rem;
  max-width: 41rem;
}

.profile-settings__wallet .input {
  color: var(--secondary-color);
}
