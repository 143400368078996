.chrome-extension {
  height: 36rem;
}

.chrome-extension__container {
  position: relative;
  padding: 4rem;
  height: 100%;
  overflow: hidden;
  background: var(--dark-gray);
  border-radius: 2rem;
}

.chrome-extension__container:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;
  height: 100%;
  width: 46rem;
  max-width: 45%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/banners/chrome-extension.png');
}

.chrome-extension__wrapper {
  width: 45rem;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title.chrome-extension__title {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 2.4rem;
  color: var(--white);
}

.chrome-extension__text {
  margin-bottom: 3rem;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 1.41;
  color: #ffffff;
  opacity: 0.7;
}

.chrome-extension__link {
  cursor: pointer;
  padding: 1.2rem 2.5rem;
  border: 0.2rem solid #fec2fe;
  color: #fec2fe;
  border-radius: 1rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
}

@media (max-width: 1150px) {
  .chrome-extension__container:after {
    right: 0;
  }
}

@media (max-width: 991px) {
  .chrome-extension__container:after {
    max-width: 35%;
  }

  .chrome-extension__wrapper {
    max-width: 55%;
  }
}

@media (max-width: 768px) {
  .chrome-extension__container:after {
    display: none;
  }

  .chrome-extension__wrapper {
    max-width: 100%;
  }

  .chrome-extension__container {
    max-width: 100%;
    border-radius: 0;
  }
}
