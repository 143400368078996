.promo-section {
  position: relative;
  margin-bottom: 10rem;
}

.promo-section__slider {
  position: relative;
  height: 45rem;
}

.promo-section__slide {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
}

.promo-section__slide-image {
  height: 100%;
  width: 100%;
}

.promo-section__slide-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2.5rem;
}

.promo-section__slide-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  box-sizing: border-box;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  z-index: 1;
}

.promo-section__slide-info:before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  transform: translate3d(0, 0, 0);
  background: rgba(29, 30, 34, 0.5);
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
}

.promo-section__slide-info .avatar {
  margin-right: 1rem;
}

.promo-section__slide-left-col,
.promo-section__slide-right-col {
  display: flex;
  align-items: center;
  z-index: 1;
}

.promo-section__info-block {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.promo-section__slide-left-col .text {
  margin-right: 2rem;
}

.promo-section__slider .swiper-button-prev,
.promo-section__slider .swiper-button-next {
  width: 6rem;
  height: 6rem;
  background: rgba(29, 30, 34, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 50%;
}

.promo-section__slider .swiper-button-prev {
  left: 3rem;
}

.promo-section__slider .swiper-button-next {
  right: 3rem;
}

.promo-section__slider .swiper-button-prev:after,
.promo-section__slider .swiper-button-next:after {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('/images/arrow.svg');
  background-repeat: no-repeat;
}

.promo-section__slider .swiper-button-next:after {
  transform: rotate(180deg);
}

.promo-section__text {
  display: flex;
  align-items: center;
}

.promo-section__text img {
  margin-right: 1rem;
}

.promo-section__text span {
  margin-right: 1rem;
}

@media (max-width: 991px) {
  .promo-section__slider {
    height: 40rem;
  }

  .promo-section__slide-image img {
    object-position: center -3rem;
  }

  .promo-section__slide-info {
    padding: 1rem;
    flex-direction: column;
  }

  .promo-section__slide-left-col {
    width: 100%;
    margin-bottom: 1rem;
  }

  .promo-section__slide-right-col {
    width: 100%;
  }

  .promo-section__text span {
    display: none;
  }
}

@media (max-width: 768px) {
  .promo-section {
    margin-bottom: 8rem;
  }

  .promo-section__slider .swiper-button-prev,
  .promo-section__slider .swiper-button-next {
    display: none;
  }

  .promo-section__slide-right-col {
    display: none;
  }

  .promo-section__slide-left-col {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .promo-section {
    display: none;
  }
}
