.active-filter {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  padding: 1rem 2rem;
}

.active-filter:not(:last-child) {
  margin-right: 1rem;
}

.active-filter__header {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 0.2rem solid var(--secondary-color);
  border-radius: 1rem;
  padding: 1rem 2rem;
}

.active-filter__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.active-filter__name {
  margin-right: 1rem;
}

.active-filter__clear {
  position: relative;
  margin-left: 1.4rem;
  cursor: pointer;
}

.active-filter__clear > div {
  position: absolute;
  top: calc(50% - 0.7rem);
  left: calc(50% - 0.7rem);
  width: 1.4rem;
  height: 1.4rem;
  opacity: 0.4;
  z-index: 0;
}

.active-filter__clear > div div {
  position: absolute;
  left: 50%;
  width: 0.2rem;
  height: 100%;
  border-radius: 1rem;
  background-color: #ffffff;
}

.active-filter__clear > div div:nth-child(1) {
  transform: rotate(45deg);
}

.active-filter__clear > div div:nth-child(2) {
  transform: rotate(-45deg);
}
