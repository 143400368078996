.pagination {
  position: relative;
}

.pagination__pages {
  display: flex;
  align-items: center;
}

.pagination__pages .pagination__page:not(:last-child) {
  margin-right: 1rem;
}

.pagination__page {
  display: flex;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5rem;
  min-width: 3rem;
  font-size: 1.6rem;
  cursor: pointer;
  color: var(--text-color-gray-03);
  border: 0.1rem solid var(--text-color-gray-03);
  transition: all 0.2s;
}

.pagination__page.pagination__page--active {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.pagination__page:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
