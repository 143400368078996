.preface-mint {
  margin-bottom: 13.3rem;
}

.preface-mint__cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}

.preface-mint__cover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.preface-mint__content {
  position: relative;
  margin-top: 20rem;
  width: 73rem;
  padding: 2.5rem 0.4rem 0.4rem 0.4rem;
  background: rgba(44, 45, 49, 0.9);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(2rem);
  transform: translate3d(0, 0, 0);
  border-radius: 2rem;
}

.preface-mint__mint-status {
  position: absolute;
  left: 2rem;
  top: 2.5rem;
}

.preface-mint__mint-btn {
  position: absolute;
  right: 2rem;
  top: 2.5rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  background-color: var(--gold);
  border-radius: 3rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: var(--black);
}

.preface-mint__mint-btn img {
  margin-right: 0.6rem;
}

.preface-mint__logo {
  position: absolute;
  top: -6rem;
  left: calc(50% - 6rem);
}

.preface-mint__title {
  padding-top: 10rem;
  margin-bottom: 2rem;
}

.preface-mint__description {
  text-align: center;
}

.preface-mint__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.preface-mint__socials a:not(:last-child) {
  margin-right: 2rem;
}

.preface-mint__mint-link {
  width: 100%;
  border-radius: 1.7rem;
}

.preface-mint__stats {
  padding: 4rem;
  box-sizing: border-box;
  border-radius: 2.5rem;
  background-color: var(--dark-gray);
}

.preface-mint__stats-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.preface-mint__stats-grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
