.title {
  font-weight: 600;
  color: var(--white);
}

h1.title {
  font-size: 12.6rem;
  font-weight: 700;
  line-height: 1.09;
}

h2.title {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 1;
}

h3.title {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1;
}

.title.title_color_rainbow {
  background: linear-gradient(
      94.58deg,
      #00ff57 10.18%,
      #fec2fe 42.8%,
      #ffe233 71.59%
    ),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title.section__title {
  margin-bottom: 4rem;
  text-align: center;
}

@media (max-width: 768px) {
  h2.title {
    font-size: 3.8rem;
  }
}
