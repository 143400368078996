.table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.table-head {
  display: flex;
  margin-bottom: 6rem;
  justify-content: space-between;
}

.table-head__extra {
  flex: 1;
}

.table {
  position: relative;
  table-layout: auto;
}

.table thead {
  position: sticky;
  top: 8.7rem;
  z-index: 1;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1) 0.1rem,
    var(--bg-dark) 0.1rem,
    var(--bg-dark) 100%
  );
  border: none;
}

.table tr {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.table tbody tr {
  cursor: pointer;
  transition: background-color 0.2s ease-in;
}

.table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

.table th,
.table td {
  font-weight: 400;
  text-align: left;
  overflow-wrap: break-word;
  vertical-align: middle;
}

.table th {
  color: rgba(255, 255, 255, 0.5);
}

.table td {
  color: var(--white);
}

.table_size_default th,
.table_size_default td {
  padding: 2rem 0;
  font-size: 1.6rem;
  line-height: 2rem;
}

.table_size_small th,
.table_size_small td {
  padding: 1rem 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.table__pagination {
  display: flex;
  justify-content: right;
  margin-top: 3rem;
}

@media (max-width: 991px) {
  .table thead {
    display: none;
  }

  .table-head {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .table-head {
    margin-bottom: 3rem;
  }

  .table th,
  .table td {
    font-size: 1.4rem;
    min-width: 10rem;
    max-width: 25rem;
  }
}
