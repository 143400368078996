.mint-content {
  position: relative;
}

.mint-content__assets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 19rem);
  grid-gap: 3rem;
  margin-bottom: 5rem;
}
