.about-section {
  position: relative;
  margin-bottom: 10rem;
}

.about-section__container {
  position: relative;
}

.about-section__background {
  z-index: -1;
  object-fit: cover;
  border-radius: 2rem;
  overflow: hidden;
}

.about-section__background img {
  object-fit: cover;
}

.about-section__wrapper {
  padding: 9.1rem 0;
  margin: auto;
  width: 55rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

h1.about-section__title {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 800;
  font-size: 6rem;
  line-height: 1.03;
  text-align: center;
}

.about-section__text {
  color: #8c8c8c;
  line-height: 2.5rem;
  text-align: center;
}

.about-section__btn-group {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.about-section__btn-group .button:not(:last-child) {
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .about-section {
    margin-bottom: 6rem;
  }

  .about-section__container {
    max-width: 100%;
  }

  .about-section__background {
    border-radius: 0;
  }

  h1.about-section__title {
    font-size: 4rem;
  }

  .about-section__text {
    max-width: 40rem;
    font-size: 1.6rem;
  }

  .about-section__wrapper {
    padding: 6rem 0;
  }
}

@media (max-width: 575px) {
  .about-section__wrapper {
    padding: 4rem 0;
  }

  .about-section__text {
    max-width: 30rem;
    line-height: 1.5;
  }
}
