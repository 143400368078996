.radio-button {
  flex: 1;
  padding: 2.8rem;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 1rem;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: center;
  cursor: pointer;
  color: var(--white);
  transition: all 0.2s;
}

.radio-button--checked {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}
