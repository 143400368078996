.loader {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
}

.loader div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border: 0.2rem solid;
  border-radius: 50%;
  animation: circle-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader_color_primary div {
  border-color: var(--primary-color) var(--primary-color) var(--primary-color)
    transparent;
}

.loader_color_secondary div {
  border-color: var(--secondary-color) var(--secondary-color)
    var(--secondary-color) transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
