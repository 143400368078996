.layout {
  position: relative;
  padding-top: 16rem;
}

.layout.mint-page,
.layout.page-collection {
  position: unset;
  padding-top: 0;
}

.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  padding-top: 8rem;
}

.layout.page-whitelist {
  padding-top: 0;
  min-height: 100vh;
  background-color: #000000;
}

@media (max-width: 768px) {
  .layout {
    padding-top: 11rem;
  }
}
