.form-field {
  position: relative;
  margin-bottom: 2rem;
}

.form-field.form-field--required .form-field__label:after {
  content: ' *';
  position: relative;
  font-size: inherit;
  color: var(--secondary-color);
}

.form-field__header {
  display: grid;
  margin-bottom: 1rem;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  width: 100%;
}
