.search-mobile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 2rem;
  width: 2.8rem;
  height: 100%;
}

.search-mobile__btn {
  position: relative;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-items: center;
}

.search-mobile__btn img {
  width: 1.87rem;
  height: 1.87rem;
}

.search-mobile__container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1.3rem 1rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5))
    10%;
  z-index: 100;
}

.search-mobile__search {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 4.4rem;
}

.search-mobile__search-input {
  flex: 1;
  margin-right: 1rem;
  background-color: var(--dark);
}

.search-mobile__close {
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 1rem;
  background-color: var(--dark);
}

.search-mobile__close div {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 0.1rem);
  width: 0.2rem;
  height: 2rem;
  background-color: rgb(126, 128, 130);
  border-radius: 0.1rem;
}

.search-mobile__close div:nth-child(1) {
  transform: rotate(45deg);
}

.search-mobile__close div:nth-child(2) {
  transform: rotate(-45deg);
}

.search-mobile .search__result {
  position: relative;
  top: unset;
  margin-top: 1rem;
  overflow-y: auto;
  max-height: calc(100% - 5rem);
}
