.nft-whitelist {
  position: relative;
}

.nft-whitelist__container {
  width: 86rem;
}

.nft-whitelist__preface {
  position: relative;
  margin-bottom: 6rem;
}

.nft-whitelist__cover {
  position: relative;
  height: 26rem;
  width: 100%;
  margin-bottom: 9rem;
  overflow: hidden;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.nft-whitelist__cover-img {
  object-fit: cover;
  height: 100%;
}

.nft-whitelist__logo {
  position: absolute;
  top: 19.5rem;
  left: calc(50% - 6.5rem);
  width: 13rem;
  height: 13rem;
}

.title.nft-whitelist__title {
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 1;
  text-align: center;
}

.nft-whitelist__text {
  width: 55rem;
  max-width: 100%;
  opacity: 0.6;
  line-height: 2.6rem;
  margin: 0 auto;
}

.nft-whitelist__tabs {
  padding-bottom: 6rem;
}

.nft-whitelist__tabs.tabs.tabs_color_primary .rc-tabs-ink-bar {
  background: #8c8edc;
}

.nft-whitelist__tabs.tabs .rc-tabs-tab b {
  color: #8c8edc;
  font-weight: 500;
}

.nft-whitelist__subtitle {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4rem;
}

.nft-whitelist__connect {
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #232528;
  border-radius: 1.2rem;
}

.button.nft-whitelist__connect-btn {
  width: 100%;
  font-size: 1.8rem;
  color: #ffe780;
  background: #8c8edc;
  border-radius: 0.8rem;
  border-color: #8c8edc;
}

.button.nft-whitelist__connect-btn:hover {
  color: #ffe780;
  background-color: #7274cb;
  border-color: #7274cb;
}

.nft-whitelist__footer {
  padding: 2.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.nft-whitelist__powered {
  display: flex;
  align-items: center;
}

.nft-whitelist__powered .text {
  margin-left: 0.9rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
}

.nft-whitelist__connect > div:first-child {
  position: relative;
  margin: 3.6rem 0 4rem 0;
}

.nft-whitelist__connect--offline > div:first-child:before {
  content: '';
  position: absolute;
  top: calc(50% - 0.4rem);
  left: -1.6rem;
  margin-right: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  border: 0.2rem solid #e5785c;
  border-radius: 50%;
}

.nft-whitelist__connect--online > div:first-child:before {
  content: '';
  position: absolute;
  top: calc(50% - 0.4rem);
  left: -1.6rem;
  margin-right: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #08db82;
  border-radius: 50%;
}

.nft-whitelist__connect-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26rem;
  max-width: 100%;
}

.nft-whitelist__connect-status > .text {
  margin-top: 1.4rem;
}

.nft-whitelist__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.nft-whitelist__socials-link {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #232528;
  transition: background-color 0.2s ease;
}

.nft-whitelist__socials-link:hover {
  background-color: #8c8edc;
}

.nft-whitelist__socials-link:first-child {
  margin-right: 2rem;
}

.nft-whitelist .text.text_size_lg {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .nft-whitelist__container:first-child {
    max-width: 100%;
  }

  .title.nft-whitelist__subtitle {
    font-weight: 600;
    font-size: 3rem;
  }
}
