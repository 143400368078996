.listing-form {
}

.listing-form__container {
  width: 55rem;
}

.listing-form__coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  margin-bottom: 10rem;
  margin-top: 5rem;
  background: var(--dark-gray);
  border-radius: 1rem;
}

.listing-form__coming-soon img {
  width: 23.9rem;
  margin-bottom: 1rem;
}

.listing-form__type {
  display: flex;
  width: 100%;
}

.listing-form__type-item {
  flex: 1;
  padding: 2.8rem;
  transition: all 0.2s;
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--white);
  border-radius: 1rem;
}

a.listing-form__type-item {
  border-left: unset;
}

.listing-form__type-item--active {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.listing-form__rules {
  padding: 3rem;
  margin-bottom: 5rem;
  box-sizing: border-box;
  border-radius: 1rem;
  background-color: var(--light-gray);
}

h3.listing-form__rules-title {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 400;
}

.listing-form__rules-title b {
  color: var(--secondary-color);
}

.listing-form__rules ul li:not(:last-child) {
  margin-bottom: 1em;
}
