.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
}

.avatar__container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: inherit;
  background-color: var(--gray);
}

.avatar__image {
  object-fit: cover;
}

.avatar_size_sm {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
}

.avatar_size_md {
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
}

.avatar_size_lg {
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
}

.avatar_size_xl {
  width: 12rem;
  height: 12rem;
  min-width: 12rem;
  min-height: 12rem;
}

.avatar_shape_circle {
  border-radius: 50%;
}

.avatar_shape_square {
  border-radius: 0.7rem;
  border-width: 0;
}

.avatar_border_light:not(.avatar_shape_square),
.avatar_border_dark:not(.avatar_shape_square),
.avatar_border_white:not(.avatar_shape_square) {
  border-width: 0.1rem;
  border-style: solid;
}

.avatar_size_xl.avatar_border_light:not(.avatar_shape_square),
.avatar_size_xl.avatar_border_dark:not(.avatar_shape_square),
.avatar_size_xl.avatar_border_white:not(.avatar_shape_square) {
  border-width: 0.2rem;
}

.avatar_border_light:not(.avatar_shape_square) {
  border-color: rgba(255, 255, 255, 0.1);
}

.avatar_border_dark:not(.avatar_shape_square) {
  border-color: var(--dark);
}

.avatar_border_white:not(.avatar_shape_square) {
  border-color: var(--white);
  filter: drop-shadow(0 0.4rem 0.4rem rgba(0, 0, 0, 0.25));
}

.avatar__blueMark {
  position: absolute;
  right: 3%;
  bottom: -3%;
  width: 1.8rem;
  height: 1.8rem;
  z-index: 1;
}

.avatar_size_xl .avatar__blueMark {
  right: 3%;
  bottom: 5%;
  width: 3rem;
  height: 3rem;
}

.avatar_size_sm .avatar__blueMark {
  right: -0.5rem;
  bottom: 5%;
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 575px) {
  .avatar_size_md {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
  }
}
