.top-collections {
  position: relative;
  margin-bottom: 10rem;
}

.top-collections .table-head {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-gap: 2rem;
}

.top-collections__button {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.top-collections__button .button {
  min-width: 16rem;
}

.top-collections__filter {
  display: grid;
  grid-template-columns: repeat(3, 12.6rem);
  justify-content: right;
  grid-gap: 1rem;
  width: 100%;
}

@media (max-width: 991px) {
  .top-collections__filter {
    justify-content: left;
  }
}

@media (max-width: 768px) {
  .top-collections {
    margin-bottom: 6rem;
  }

  .top-collections__filter {
    grid-template-columns: repeat(3, 10.6rem);
  }
}

@media (max-width: 575px) {
  .top-collections__button .button {
    width: 100%;
  }
}
