.header {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid transparent;
  z-index: 98;
  transition: background-color 0.2s ease-in, border-bottom-color 0.2s ease-in;
}

.header_theme_default {
  background-color: var(--bg-dark);
}

.header_theme_ghost {
  background-color: transparent;
}

.header--sticky {
  position: fixed;
  padding-top: 2rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}

.header__container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  margin-right: 3rem;
}

.header .search {
  flex: 1;
  margin-right: 4rem;
}

.header_theme_ghost.header--sticky {
  background-color: var(--bg-dark);
}

.header .navigation {
  margin-right: 4rem;
}

.button.header__button-list {
  margin-right: 1rem;
  color: var(--white);
}

.header_theme_ghost .button.header__button-list {
  border-color: var(--white);
}

.header_theme_ghost .button.header__button-list:hover {
  border-color: var(--white);
}

.header__menu {
  position: absolute;
  padding: 1rem 0;
  right: 0;
  top: calc(100% + 1rem);
  min-width: 18rem;
  background: var(--dark-gray);
  border: 0.1rem solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 1rem;
  z-index: 99;
}

.header__menu li {
  padding: 1rem 2rem;
  display: flex;
  justify-content: left;
  transition: all 0.3s;
}

.header__menu li:hover {
  color: var(--secondary-color);
  background-color: rgba(255, 255, 255, 0.1);
}

.header .search .search__input-group {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.header.header--search-visible .search .search__input-group {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .header__container {
    width: 90%;
  }

  .header .logo {
    z-index: 99;
  }

  .header .search {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
  }
}
