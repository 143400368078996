.navigation-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 1rem 0;
  background-color: var(--dark);
  z-index: 98;
}

.navigation-mobile__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.navigation-mobile__navigation {
  padding-top: 10rem;
}

.navigation-mobile__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.navigation-mobile__list li:not(:last-child) {
  margin-bottom: 1rem;
}

.navigation-mobile__link {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.28;
  color: #fff;
}

.navigation-mobile__social-links {
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13.9rem, 1fr));
  grid-gap: 1rem;
}

.navigation-mobile__social-links .button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-mobile__social-links .button img {
  margin-right: 1rem;
}

.navigation-mobile__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
