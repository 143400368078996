.range-slider {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.range-slider__thumb {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  background: var(--white);
  outline: none;
}

.range-slider__track {
  height: 0.2rem;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  width: 100%;
}

.range-slider__output {
  position: absolute;
  top: -2rem;
  left: -1.2rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  color: var(--white);
  font-family: 'GT Walsheim Pro', sans-serif;
  font-size: 1.2rem;
  line-height: 1.33;
}
