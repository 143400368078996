:root {
  --g-rows: 1;
  --g-columns: 12;
  --g-gap: 2rem;
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--g-rows, 1), 1fr);
  grid-template-columns: repeat(var(--g-columns, 12), 1fr);
  gap: var(--g-gap, 2rem);
}

/* g-col */
.grid .g-col-1 {
  grid-column: auto/span 1;
}

.grid .g-col-2 {
  grid-column: auto/span 2;
}

.grid .g-col-3 {
  grid-column: auto/span 3;
}

.grid .g-col-4 {
  grid-column: auto/span 4;
}

.grid .g-col-6 {
  grid-column: auto/span 6;
}

.grid .g-col-8 {
  grid-column: auto/span 8;
}

.grid .g-col-9 {
  grid-column: auto/span 9;
}

.grid .g-col-12 {
  grid-column: auto/span 12;
}

/* g-gutter */
.grid.g-gutter-1 {
  grid-gap: 1rem;
}

.grid.g-gutter-2 {
  grid-gap: 2rem;
}

.grid.g-gutter-3 {
  grid-gap: 3rem;
}

.grid.g-gutter-4 {
  grid-gap: 4rem;
}

.grid.g-gutter-5 {
  grid-gap: 5rem;
}

@media (max-width: 575px) {
  .grid .g-col-xs-1 {
    grid-column: auto/span 1;
  }

  .grid .g-col-xs-2 {
    grid-column: auto/span 2;
  }

  .grid .g-col-xs-3 {
    grid-column: auto/span 3;
  }

  .grid .g-col-xs-4 {
    grid-column: auto/span 4;
  }

  .grid .g-col-xs-6 {
    grid-column: auto/span 6;
  }

  .grid .g-col-xs-8 {
    grid-column: auto/span 8;
  }

  .grid .g-col-xs-9 {
    grid-column: auto/span 9;
  }

  .grid .g-col-xs-12 {
    grid-column: auto/span 12;
  }

  /* g-gutter */
  .grid.g-gutter-xs-1 {
    grid-gap: 1rem;
  }

  .grid.g-gutter-xs-2 {
    grid-gap: 2rem;
  }

  .grid.g-gutter-xs-3 {
    grid-gap: 3rem;
  }

  .grid.g-gutter-xs-4 {
    grid-gap: 4rem;
  }

  .grid.g-gutter-xs-5 {
    grid-gap: 5rem;
  }
}

@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }

  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }

  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }

  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }

  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }

  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }

  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }

  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }

  /* g-gutter */
  .grid.g-gutter-sm-1 {
    grid-gap: 1rem;
  }

  .grid.g-gutter-sm-2 {
    grid-gap: 2rem;
  }

  .grid.g-gutter-sm-3 {
    grid-gap: 3rem;
  }

  .grid.g-gutter-sm-4 {
    grid-gap: 4rem;
  }

  .grid.g-gutter-sm-5 {
    grid-gap: 5rem;
  }
}

@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }

  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }

  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }

  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }

  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }

  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }

  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }

  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }

  /* g-gutter */
  .grid.g-gutter-md-1 {
    grid-gap: 1rem;
  }

  .grid.g-gutter-md-2 {
    grid-gap: 2rem;
  }

  .grid.g-gutter-md-3 {
    grid-gap: 3rem;
  }

  .grid.g-gutter-md-4 {
    grid-gap: 4rem;
  }

  .grid.g-gutter-md-5 {
    grid-gap: 5rem;
  }
}

@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }

  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }

  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }

  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }

  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }

  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }

  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }

  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }

  /* g-gutter */
  .grid.g-gutter-lg-1 {
    grid-gap: 1rem;
  }

  .grid.g-gutter-lg-2 {
    grid-gap: 2rem;
  }

  .grid.g-gutter-lg-3 {
    grid-gap: 3rem;
  }

  .grid.g-gutter-lg-4 {
    grid-gap: 4rem;
  }

  .grid.g-gutter-lg-5 {
    grid-gap: 5rem;
  }
}

@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }

  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }

  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }

  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }

  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }

  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }

  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }

  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }

  /* g-gutter */
  .grid.g-gutter-xl-1 {
    grid-gap: 1rem;
  }

  .grid.g-gutter-xl-2 {
    grid-gap: 2rem;
  }

  .grid.g-gutter-xl-3 {
    grid-gap: 3rem;
  }

  .grid.g-gutter-xl-4 {
    grid-gap: 4rem;
  }

  .grid.g-gutter-xl-5 {
    grid-gap: 5rem;
  }
}
