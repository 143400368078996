.latest-card {
  position: relative;
  background: var(--dark-gray);
  border-radius: 1.5rem;
  cursor: pointer;
}

.latest-card__cover {
  position: relative;
  width: 100%;
  height: 12rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  overflow: hidden;
  transform: translateZ(0);
}

.latest-card:hover .latest-card__cover-img {
  transform: scale(1.1);
}

.latest-card__cover-img {
  overflow: hidden;
  object-fit: cover;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
}

.latest-card__logo {
  position: absolute;
  left: calc(50% - 4rem);
  top: 8rem;
}

.latest-card__logo.avatar_size_md {
  border-width: 0.2rem;
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  min-height: 8rem;
}

.latest-card__header,
.latest-card__footer {
  position: relative;
  padding: 0 1rem 1rem 1rem;
}

.latest-card__footer {
  margin-top: 1rem;
}

.latest-card__title {
  margin-top: 6rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: center;
}

.latest-card__stats {
  padding: 1.4rem 2rem;
  background: linear-gradient(0deg, #3b3c41, #3b3c41), #d9d9d9;
  border-radius: 0.7rem;
}

.latest-card__stats-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.latest-card__stats-item img {
  opacity: 0.5;
}

.latest-card__stats-item > span {
  margin-left: 0.5rem;
  font-weight: inherit;
}

@media (max-width: 768px) {
  .latest-card__stats {
    padding: 1rem;
  }

  .latest-card__stats-item {
    font-size: 1.2rem;
  }

  .latest-card__title {
    font-size: 2rem;
  }
}
