.drops-calendar {
  position: relative;
  margin-bottom: 5rem;
}

.drops-calendar__title {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 10rem;
}

.drops-calendar__header h3.title {
  margin-bottom: 3rem;
}

.drops-calendar__filter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-column-gap: 5rem;
  margin-bottom: 5rem;
}

.drops-calendar__filter-range {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 11.5rem 11.5rem;
}

.drops-calendar .form-field__label {
  color: rgba(255, 255, 255, 0.5);
}

.drops-calendar__day {
  margin-bottom: 10rem;
}

.drops-calendar__day h3.title {
  margin-bottom: 6rem;
  font-weight: 600;
  font-size: 4.8rem;
}

@media (max-width: 768px) {
  .drops-calendar__title {
    margin-bottom: 5rem;
  }

  .drops-calendar__day {
    margin-bottom: 8rem;
  }

  .drops-calendar__day h3.title {
    margin-bottom: 4rem;
    font-size: 3.8rem;
  }
}
