.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  background: rgba(29, 30, 34, 0.8);
  backdrop-filter: blur(30px);
  z-index: 101;
}

.modal__container {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  padding: 2rem;
  max-width: 95%;
  border-radius: 2.5rem;
  background-color: var(--dark-gray);
}

.modal__close {
  position: absolute;
  top: 0;
  right: -8rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--dark-gray);
}

.modal__close div {
  position: absolute;
  left: 2.9rem;
  height: 2rem;
  width: 0.2rem;
  background-color: rgba(255, 255, 255, 0.7);
}

.modal__close div:nth-child(1) {
  transform: rotate(45deg);
}

.modal__close div:nth-child(2) {
  transform: rotate(-45deg);
}

.modal__body {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.modal__body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media (max-width: 768px) {
  .modal__close {
    top: 2rem;
    right: 2rem;
    z-index: 1;
    background-color: transparent;
  }
}
