.button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-weight: 600;
  text-align: center;
  border-radius: 1rem;
  transition: all 0.3s;
}

.button:disabled {
  cursor: not-allowed;
}

.button_color_light:disabled {
  background-color: var(--light-color-hover);
}

.button_color_default {
  border: 0.2rem solid var(--btn-default-border-color);
  color: var(--white);
  background: transparent;
}

.button_color_default:hover:not(:disabled) {
  border: 0.2rem solid var(--btn-default-border-color);
  color: var(--white);
}

.button_color_primary {
  color: var(--white);
  background-color: var(--primary-color);
  border: 0.2rem solid var(--primary-color);
}

.button_color_primary:hover:not(:disabled) {
  background-color: var(--primary-color-hover);
  border-color: transparent;
}

.button_color_secondary {
  color: var(--white);
  background-color: transparent;
  border: 0.2rem solid var(--secondary-color);
}

.button_color_secondary:hover:not(:disabled) {
  border: 0.2rem solid var(--secondary-color);
}

.button_color_light {
  font-size: 1.6rem;
  color: var(--black);
  background-color: var(--light-color);
}

.button_color_light:hover:not(:disabled) {
  background-color: var(--light-color-hover);
}

.button_color_plum {
  font-size: 1.6rem;
  color: var(--black);
  background-color: var(--plum);
}

.button_size_md {
  padding: 1.2rem 3rem;
  font-size: 1.6rem;
}

.button_size_sm {
  padding: 0.7rem 1.5rem;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .button_size_md {
    padding: 1.2rem 1rem;
  }
}
