.collections-list {
  position: relative;
}

.collections-list__header {
  margin-bottom: 5rem;
}

.collections-list .table-head {
  align-items: center;
}

.collections-list__filter {
  display: flex;
  justify-content: right;
  margin-top: 3rem;
}

.collections-list__filter .form-field:not(:last-child) {
  margin-right: 2rem;
}

.collections-list__filter .form-field__label {
  color: rgba(255, 255, 255, 0.5);
}

.collections-list__grid {
  display: grid;
  grid-gap: 5rem;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
