.icon path {
  transition: all 0.3s ease;
}

.icon-twitter:hover path,
.icon-instagram:hover path,
.icon-opensea:hover path,
.icon-discord:hover path,
.icon-website:hover path,
.icon-looksrare:hover path,
.icon-gem:hover path {
  opacity: 0.7;
  /*fill: var(--primary-color);*/
}
